<body *ngIf="isLogin==false">
  <a href="javascript:" id="return-to-top"><i class="fa fa-arrow-up"></i></a>
  <div class="container-scroller">
    <app-header></app-header>
    <div class="container-fluid page-body-wrapper" style="position:absolute;">
      <app-sidebar></app-sidebar>
      <div class="main-panel">
        <div class="page-wrapper pt-4">
          <div class="page-content pt-0 pe-0 ps-0">
            <!-- ----------------------------------------------------------------------------------------------------------------- -->
            <section class="mt-2 pt-3 pb-5 bg-all-course">

              <div class="container pb-5">
                <div class="row">
                  <div class="col-xl-9">

                    <div class="card mb-3 border-0  bg-transparent">
                      <div class="row g-0">
                        <div class="col-md-4">
                          <img [src]="courseDetails.IMAGE_URL" class="img-fluid rounded" alt="...">
                        </div>
                        <div class="col-md-8 ps-4">
                          <div class="card-body  bg-transparent">
                            <h5 class="card-title">{{courseDetails.COURSE_NAME}}</h5>
                            <!-- <h6 style="color:#808080">by Prof. {{courseDetails.FIRSTNAME}}
                  {{courseDetails.LASTNAME}}</h6> -->
                            <p class="card-text">{{courseDetails.COURSE_DESCRIPTION}} </p>
                            <ul>
                              <li *ngFor="let item of courseDetails.moreoptions">{{item}}</li>
                            </ul>
                            <div class="d-none">
                              <span><i class="fa-solid fa-star checked"></i></span>
                              <span><i class="fa-solid fa-star checked"></i></span>
                              <span><i class="fa-solid fa-star checked"></i></span>
                              <span><i class="fa-solid fa-star checked"></i></span>
                              <span><i class="fa-solid fa-star"></i></span>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 date">
                    <p class="date_time d-none" style="color:#5E5E5E; font-size: 13px">Program Date:
                      <span class="me-2 ms-1 fw-bold">
                        {{sessionDetails.COURSESHD_STARTDATE |date:'dd-MMM-yyyy'}} -
                        {{sessionDetails.COURSESHD_ENDDATE |date:'dd-MMM-yyyy'}}
                      </span>
                    </p>

                    <select class="form-select date_select d-none" [(ngModel)]="sessionDetails.COURSESHD_ID"
                      (change)="sessionChanged($event.target.value)" aria-label="Default select example">
                      <option value="0">Select Timings</option>
                      <option *ngFor="let session of sessionList" [value]="session.COURSESHD_ID">
                        {{session.COURSESHD_STARTTIME | date:'hh:mm a'}} -
                        {{session.COURSESHD_ENDTIME | date:'hh:mm a'}}</option>
                    </select>

                    <div class="d-block mt-3 mb-3 d-none">
                      <span class=" float-start price-offer-1"><i class="fa-solid fa-indian-rupee-sign"></i>
                        <!-- {{sessionDetails.COURSE_FEES|currency:' KSH '}} -->
                        {{courseFee|currency:' KSH '}}
                        <!-- <span
                                      class="price-offer-2">10,000</span> -->
                      </span>
                      <!-- <span class="float-end">dsfcds</span> -->
                    </div>



                    <!-- <div class="form-check form-check-inline pt-3">
                              <input class="form-check-input" type="checkbox" id="inlineCheckbox1"
                                  value="option1">
                              <label class="form-check-label" for="inlineCheckbox1">I would like to enroll
                                  Lorem ipsum dolor
                                  sit amet, consectetur adipiscing elit. Sed eget magna enim fermentum
                              </label>
                          </div> -->
                    <br>

                    <div class="d-grid mt-3">
                      <!-- {{!isAlreadyCourseTaken}} 
                      *ngIf="sessionDetails.COURSESHD_ID > 0 && ( isCourseExpired && (!isAlreadyCourseTaken))"-->
                      <button 
                        type="button" (click)="openModal1()" class="btn btn-enroll">Apply
                        Now</button>
                      <!-- data-bs-target="#myModal" data-bs-toggle="modal" -->
                    </div>

                  </div>
                </div>


              </div>


            </section>
            <!-- -----------------------------*ngIf="tenantCode !='88968955'"----------------------------------------------------------------------------------------- -->
            <section style="margin-top: -3rem;" >
              <div class="container d-none">
                <div class="row">
                  <div class="col-xl-9">
                    <div class="card shadow-lg">
                      <div class="card-body p-5 about_course">

                        <div class="accordion accordion-flush" id="accordionFlushExample">
                          <div class="accordion-item" *ngFor="let year of courseYear; let yearIndex = index">
                            <h2 class="accordion-header" [attr.id]="'heading_one' + yearIndex">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                [attr.data-bs-target]="'#collapse_one' + yearIndex"
                                [attr.aria-expanded]="yearIndex === 0 ? 'true' : 'false'"
                                [attr.aria-controls]="'collapse_one' + yearIndex"
                                (click)="viewSemester(year.COURSESHD_COURSEYEAR)">
                                <b> Course: {{ year.COURSE_YEAR }} </b>
                              </button>
                            </h2>

                            <div [id]="'collapse_one' + yearIndex" class="accordion-collapse collapse"
                              [attr.aria-labelledby]="'heading_one' + yearIndex"
                              data-bs-parent="#accordionFlushExample">
                              <div class="accordion-body">
                                <div class="accordion accordion-flush" [id]="'accordionFlushExampleOne' + yearIndex">
                                  <div class="accordion-item"
                                    *ngFor="let semester of semesterList; let semesterIndex = index">
                                    <h2 class="accordion-header"
                                      [attr.id]="'heading_Two' + yearIndex + '-' + semesterIndex">
                                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        [attr.data-bs-target]="'#collapse_Two' + yearIndex + '-' + semesterIndex"
                                        [attr.aria-expanded]="semesterIndex === 0 ? 'true' : 'false'"
                                        [attr.aria-controls]="'collapse_Two' + yearIndex + '-' + semesterIndex"
                                        (click)="viewSubjects(semester.COURSESHD_SEMESTER)">
                                        <b> {{ semester.SEMESTER_NAME }} </b>
                                      </button>
                                    </h2>
                                    <div [id]="'collapse_Two' + yearIndex + '-' + semesterIndex"
                                      class="accordion-collapse collapse"
                                      [attr.aria-labelledby]="'heading_Two' + yearIndex + '-' + semesterIndex"
                                      [attr.data-bs-parent]="'#accordionFlushExampleOne' + yearIndex">
                                      <div class="accordion-body">
                                        <div class="accordion accordion-flush"
                                          [id]="'accordionFlushExampleTwo' + yearIndex + '-' + semesterIndex">
                                          <div class="accordion-item"
                                            *ngFor="let subject of subjects; let subjectIndex = index">
                                            <h2 class="accordion-header"
                                              [attr.id]="'heading_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex">
                                              <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse"
                                                [attr.data-bs-target]="'#collapse_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex"
                                                [attr.aria-expanded]="subjectIndex === 0 ? 'true' : 'false'"
                                                [attr.aria-controls]="'collapse_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex"
                                                (click)="viewChapters(subject.SUBJECT_ID)">
                                                <b> {{ subject.SUBJECT_NAME }} </b>
                                              </button>
                                            </h2>
                                            <div
                                              [id]="'collapse_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex"
                                              class="accordion-collapse collapse"
                                              [attr.aria-labelledby]="'heading_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex"
                                              [attr.data-bs-parent]="'#accordionFlushExampleTwo' + yearIndex + '-' + semesterIndex">
                                              <div class="accordion-body">
                                                <ul *ngFor="let chapter of chapters; let chapterIndex = index">
                                                  <b> chapter {{ chapterIndex + 1 }} : {{ chapter.CHAPTER_NAME }}</b>
                                                  <!-- <li [innerHTML]="chapter.CHAPTER_DESCRIPTION"></li> -->
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- --------------------------------------------------------------------------------------------------------------------------------------- -->
</body>

<!-- --------------------------------------------------------------------------------------------------------------------------------- -->
<div class="container-scroller" class="d-none">
  <app-header></app-header>
  <div class="container-fluid page-body-wrapper" style="position:absolute;">
    <app-sidebar></app-sidebar>
    <div class="main-panel">
      <div class="page-wrapper pt-4">
        <div class="page-content pt-0 pe-0 ps-0">

          <section class="mt-2 pt-3 pb-5 bg-all-course">

            <div class="container pb-5">
              <div class="row">
                <div class="col-xl-9">

                  <div class="card mb-3 border-0  bg-transparent">
                    <div class="row g-0">
                      <div class="col-md-4">
                        <img [src]="courseDetails.IMAGE_URL" class="img-fluid rounded" alt="...">
                      </div>
                      <div class="col-md-8 ps-4">
                        <div class="card-body  bg-transparent">
                          <h5 class="card-title">{{courseDetails.COURSE_NAME}}</h5>
                          <!-- <h6 style="color:#808080">by Prof. {{courseDetails.FIRSTNAME}}
                            {{courseDetails.LASTNAME}}</h6> -->
                          <p class="card-text">{{courseDetails.COURSE_DESCRIPTION}} </p>
                          <ul>
                            <li *ngFor="let item of courseDetails.moreoptions">{{item}}</li>
                          </ul>
                          <div class="d-none">
                            <span><i class="fa-solid fa-star checked"></i></span>
                            <span><i class="fa-solid fa-star checked"></i></span>
                            <span><i class="fa-solid fa-star checked"></i></span>
                            <span><i class="fa-solid fa-star checked"></i></span>
                            <span><i class="fa-solid fa-star"></i></span>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 date">
                  <p class="date_time d-none" style="color:#5E5E5E; font-size: 13px">Course Date:
                    <span class="me-2 ms-1 fw-bold">
                      {{sessionDetails.COURSESHD_STARTDATE |date:'dd-MMM-yyyy'}} -
                      {{sessionDetails.COURSESHD_ENDDATE |date:'dd-MMM-yyyy'}}
                    </span>
                  </p>

                  <select class="form-select date_select d-none" [(ngModel)]="sessionDetails.COURSESHD_ID"
                    (change)="sessionChanged($event.target.value)" aria-label="Default select example">
                    <option value="0">Select Timings</option>
                    <option *ngFor="let session of sessionList" [value]="session.COURSESHD_ID">
                      {{session.COURSESHD_STARTTIME | date:'hh:mm a'}} -
                      {{session.COURSESHD_ENDTIME | date:'hh:mm a'}}</option>
                  </select>

                  <div class="d-block mt-3 mb-3 d-none">
                    <span class=" float-start price-offer-1"><i class="fa-solid fa-indian-rupee-sign"></i>
                      <!-- {{sessionDetails.COURSE_FEES|currency:' KSH '}} -->
                      {{courseFee|currency:' KSH '}}
                      <!-- <span
                                                class="price-offer-2">10,000</span> -->
                    </span>
                    <!-- <span class="float-end">dsfcds</span> -->
                  </div>



                  <!-- <div class="form-check form-check-inline pt-3">
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1"
                                            value="option1">
                                        <label class="form-check-label" for="inlineCheckbox1">I would like to enroll
                                            Lorem ipsum dolor
                                            sit amet, consectetur adipiscing elit. Sed eget magna enim fermentum
                                        </label>
                                    </div> -->
                  <br>

                  <div class="d-grid mt-3">
                    <!-- {{!isAlreadyCourseTaken}} -->
                    <button *ngIf="sessionDetails.COURSESHD_ID > 0 && ( isCourseExpired && (!isAlreadyCourseTaken))"
                      type="button" (click)="openModal1()" class="btn btn-enroll">Apply
                      Now</button>
                    <!-- data-bs-target="#myModal" data-bs-toggle="modal" -->
                  </div>

                </div>
              </div>


            </div>


          </section>

          <section style="margin-top: -3rem;" class="d-none">
            <div class="container">
              <div class="row">
                <div class="col-xl-9">
                  <div class="card shadow-lg">
                    <div class="card-body p-5 about_course">

                      <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item" *ngFor="let year of courseYear; let yearIndex = index">
                          <h2 class="accordion-header" [attr.id]="'heading_one' + yearIndex">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              [attr.data-bs-target]="'#collapse_one' + yearIndex"
                              [attr.aria-expanded]="yearIndex === 0 ? 'true' : 'false'"
                              [attr.aria-controls]="'collapse_one' + yearIndex"
                              (click)="viewSemester(year.COURSESHD_COURSEYEAR)">
                              <b> Course: {{ year.COURSE_YEAR }} </b>
                            </button>
                          </h2>

                          <div [id]="'collapse_one' + yearIndex" class="accordion-collapse collapse"
                            [attr.aria-labelledby]="'heading_one' + yearIndex" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                              <div class="accordion accordion-flush" [id]="'accordionFlushExampleOne' + yearIndex">
                                <div class="accordion-item"
                                  *ngFor="let semester of semesterList; let semesterIndex = index">
                                  <h2 class="accordion-header"
                                    [attr.id]="'heading_Two' + yearIndex + '-' + semesterIndex">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                      [attr.data-bs-target]="'#collapse_Two' + yearIndex + '-' + semesterIndex"
                                      [attr.aria-expanded]="semesterIndex === 0 ? 'true' : 'false'"
                                      [attr.aria-controls]="'collapse_Two' + yearIndex + '-' + semesterIndex"
                                      (click)="viewSubjects(semester.COURSESHD_SEMESTER)">
                                      <b> {{ semester.SEMESTER_NAME }} </b>
                                    </button>
                                  </h2>
                                  <div [id]="'collapse_Two' + yearIndex + '-' + semesterIndex"
                                    class="accordion-collapse collapse"
                                    [attr.aria-labelledby]="'heading_Two' + yearIndex + '-' + semesterIndex"
                                    [attr.data-bs-parent]="'#accordionFlushExampleOne' + yearIndex">
                                    <div class="accordion-body">
                                      <div class="accordion accordion-flush"
                                        [id]="'accordionFlushExampleTwo' + yearIndex + '-' + semesterIndex">
                                        <div class="accordion-item"
                                          *ngFor="let subject of subjects; let subjectIndex = index">
                                          <h2 class="accordion-header"
                                            [attr.id]="'heading_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex">
                                            <button class="accordion-button collapsed" type="button"
                                              data-bs-toggle="collapse"
                                              [attr.data-bs-target]="'#collapse_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex"
                                              [attr.aria-expanded]="subjectIndex === 0 ? 'true' : 'false'"
                                              [attr.aria-controls]="'collapse_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex"
                                              (click)="viewChapters(subject.SUBJECT_ID)">
                                              <b> {{ subject.SUBJECT_NAME }} </b>
                                            </button>
                                          </h2>
                                          <div
                                            [id]="'collapse_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex"
                                            class="accordion-collapse collapse"
                                            [attr.aria-labelledby]="'heading_Three' + yearIndex + '-' + semesterIndex + '-' + subjectIndex"
                                            [attr.data-bs-parent]="'#accordionFlushExampleTwo' + yearIndex + '-' + semesterIndex">
                                            <div class="accordion-body">
                                              <ul *ngFor="let chapter of chapters; let chapterIndex = index">
                                                <b> chapter {{ chapterIndex + 1 }} : {{ chapter.CHAPTER_NAME }}</b>
                                                <!-- <li [innerHTML]="chapter.CHAPTER_DESCRIPTION"></li> -->
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>




                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <app-footer></app-footer>
        </div>
      </div>
      <!-- <router-outlet></router-outlet> -->
    </div>

  </div>
</div>

<!-- -------------------------------------------------------------------------------------------------------------------------------------------------- -->


<!-- --------------------------------------------------APPLICATION FORM--------------------------------------------------------------------- -->
<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header py-2">
        <h6 class="modal-title w-100"> Application Form </h6>
        <!-- <button type="button" (click)="close()" class="close " data-bs-dismiss="modal"><i class="fas fa-times"
            id='md_close'></i></button> -->
      </div>

      <!-- Modal body -->
      <div class="modal-body text-center">
        <form class="well form-horizontal ">
          <fieldset>
            <div class="row ">
              <div class="col-xl-12">
                <div class="form ">
                  <!-- -----------------------------------------------ACCORDIONS----------------------------------------------------------------- -->
                  <!-- *ngIf="(aprvId == 0|| aprvId==2 ) && (payId == 0)" -->
                  <div class="card card1 mb-3" *ngIf="(aprvId == 0|| aprvId==2 ) && (payId == 0)">
                    <div class="card-body px-4">
                      <form action="" [formGroup]="signUpForm" (ngOnSubmit)="save(signUpForm,'')">
                        <div class="row">

                          <div class="accordion" id="accordionPanelsStayOpenExample">
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                  data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                                  aria-controls="panelsStayOpen-collapseOne">
                                  (A) Personal Details
                                </button>
                              </h2>
                              <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
                                aria-labelledby="panelsStayOpen-headingOne">
                                <div class="accordion-body">
                                  <div class="row">
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <label class="form-label mb-1 float-start">First Name
                                      </label>
                                      <input type="text" formControlName="FIRSTNAME"
                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                        class="form-control form-control-sm">
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <label class="form-label mb-1 float-start">Middle Name
                                      </label>
                                      <input type="text" formControlName="MIDDLENAME"
                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                        class="form-control form-control-sm">
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <label class="form-label mb-1 float-start">Last Name
                                      </label>
                                      <input type="text" formControlName="LASTNAME"
                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                        class="form-control form-control-sm">
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <label class="form-label mb-1 float-start">Email Address<span
                                          class="text-danger">*</span>
                                      </label>
                                      <input type="text" disabled [value]="userDetails?.result?.username"
                                        class="form-control form-control-sm">
                                    </div>


                                    <!-- <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <label for="textfiled1" class="form-label mb-1 float-start">KCSE Index No
                                      </label>
                                      <input type="text" formControlName="KCSE_INDEX_NO"
                                        class="form-control form-control-sm" id="textfiled1"
                                       >
                                    </div> -->

                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">P.O Box.<span
                                          class="text-danger">*</span>
                                      </label>
                                      <input type="text" formControlName="POSTAL_ADDRESS"
                                        class="form-control form-control-sm" id="textfiled1">
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">Postal Code
                                      </label>
                                      <input type="text" formControlName="POSTAL_CODE"
                                        class="form-control form-control-sm" id="textfiled1">
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">Physical Address<span
                                          class="text-danger">*</span>
                                      </label>
                                      <input type="text" formControlName="PHYSICAL_ADDRESS"
                                        class="form-control form-control-sm" id="textfiled1">
                                    </div>

                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">Birth Date<span
                                          class="text-danger">*</span>
                                      </label>
                                      <input type="date" formControlName="DOB" (change)="agevalidate($event)"
                                        (keydown)="disableDate()" [max]="getToday()" [(ngModel)]="dateofbirth"
                                        class="form-control form-control-sm" id="textfiled1">
                                    </div>

                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">Nationality<span
                                          class="text-danger">*</span>
                                      </label>
                                      <input type="text" formControlName="NATIONALITY"
                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                        class="form-control form-control-sm" id="textfiled1">
                                    </div>

                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start"> Town/City
                                      </label>
                                      <input type="text" formControlName="NEAREST_TOWN"
                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                        class="form-control form-control-sm" id="textfiled1">
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start"> Country<span
                                          class="text-danger">*</span>
                                      </label>
                                      <select class="form-control form-control-sm" formControlName="COUNTRY_ID">
                                        <option value="">Select</option>
                                        <option *ngFor='let item of country' [value]='item.DICTIONARYID'>
                                          {{item.DICTIONARYNAME}}</option>
                                      </select>
                                      <!-- <input type="text" formControlName="NEAREST_TOWN" class="form-control form-control-sm"
                                      id="textfiled1"  > -->
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">ID/Passport No<span
                                          class="text-danger">*</span>
                                      </label>
                                      <input type="text" formControlName="ID_PASSPORT"
                                        class="form-control form-control-sm" id="textfiled1">
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">Gender<span
                                          class="text-danger">*</span>
                                      </label>
                                      <select formControlName="GENDER" class="form-control form-control-sm">
                                        <option value="">Select</option>
                                        <option *ngFor='let item of gender' [value]='item.DICTIONARYID'>
                                          {{item.DICTIONARYNAME}}</option>
                                      </select>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">Mobile Number<span
                                          class="text-danger">*</span>
                                      </label>
                                      <input type="text" formControlName="MOBILENO" (keypress)="onKeyPress($event)"
                                        maxlength="12" class="form-control form-control-sm">
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">Martial Status<span
                                          class="text-danger">*</span>
                                      </label>
                                      <select formControlName="MARTIALSTATUS" class="form-control form-control-sm">
                                        <option value="">Select</option>
                                        <option *ngFor='let item of MartialStatus' [value]='item.DICTIONARYID'>
                                          {{item.DICTIONARYNAME}}</option>
                                      </select>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <label for="textfiled1" class="form-label mb-1 float-start">Languages</label>
                                      <input type="text" class="form-control form-control-sm"
                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                        formControlName="PROGRAMME_CATEGORY_ID">
                                      <!-- <select formControlName="PROGRAMME_CATEGORY_ID"
                                        class="form-control form-control-sm">
                                        <option value="">Select</option>
                                        <option value="1">Post Graduate</option>
                                        <option value="2">Degree</option>
                                        <option value="3">Diploma</option>
                                        <option value="4">Certificate</option>
                                        <option value="5">KCSE</option>
                                      </select> -->
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <label for="textfiled1" class="form-label mb-1 float-start">Program Selected
                                        <span class="text-danger">*</span></label>
                                      <select [(ngModel)]="courseId" [ngModelOptions]="{standalone:true}" disabled
                                        class="form-control form-control-sm">
                                        <option value="">Select</option>
                                        <option *ngFor="let item of  courseList" [value]="item.COURSE_ID">
                                          {{item.COURSE_NAME}}
                                        </option>
                                        <!-- <option value="1">Bachelor Of Data Science</option>
                                      <option value="2">Bachelor Of Cyber Security And Digital Forensics
                                      </option>
                                      <option value="3">Bachelor Of Technology Education</option>
                                      <option value="4">Bachelor Of Bussiness And Enterpreneurship</option>
                                      <option value="5">Bachelor Of Economics And Statistics</option>
                                      <option value="6">Bachelor Of AgriTechnology and Food Systems</option>
                                      <option value="7">Postgraduate Diploma in Learning Design and Technology
                                      </option>
                                      <option value="8">Postgraduate Diploma in Leadership</option> -->
                                      </select>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <label for="textfiled1" class="form-label mb-1 float-start">Mode Of Study</label>
                                      <select formControlName="MODE_OF_STUDY" class="form-control form-control-sm">
                                        <option value="">Select</option>
                                        <option value="1">Fulltime</option>
                                        <option value="2">Part-time
                                        </option>
                                        <option value="3">Modular</option>
                                      </select>
                                    </div>

                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <label for="textfiled1" class="form-label mb-1 float-start">Passport Size
                                        Photograph<span class="text-danger">*</span>
                                      </label>
                                      <input type="file" formControlName="UPLOAD_4" (change)="changeFile($event,4)"
                                        class="form-control form-control-sm" id="textfiled1">
                                      <p class="text-danger float-start">Max 30Mb (upload only PDF,JPG,PNG,JPEG)</p><br>
                                      <p *ngIf="upload_2 != 0">{{upload_2}}</p>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-6 col-12 mb-3 pt-2">
                                      <label for="textfiled1" class="form-label mb-3 float-start">Do you have any form
                                        of
                                        physical
                                        disability ? </label>
                                      <select formControlName="PHYSICAL_DISABILITY"
                                        class="form-control form-control-sm pt">
                                        <option value="" [selected]="true">Select</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No
                                        </option>

                                      </select>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3 ">
                                      <label for="textfiled1" class="form-label mb-1 float-start">How did you learn
                                        about
                                        OUK
                                        programmes? Please tick as appropriate </label>

                                      <select formControlName="OUK_PROGRAMMES" [(ngModel)]="oukPrograms"
                                        class="form-control form-control-sm">
                                        <option value="">Select</option>
                                        <option value="1">News papers</option>
                                        <option value="2">Television/Radio
                                        </option>
                                        <option value="3">OUK Website</option>
                                        <option value="4">Social media</option>
                                        <option value="5">Career Fair/ Exhibition</option>
                                        <option value="6">Others </option>
                                      </select>

                                      <input type="text" class="form-control form-control-sm pt-2"
                                        *ngIf="oukPrograms == '6'">
                                    </div> -->




                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                  data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                                  aria-controls="panelsStayOpen-collapseTwo">
                                  (B) Next of Kin/Emergency Contact
                                </button>
                              </h2>
                              <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
                                aria-labelledby="panelsStayOpen-headingTwo">
                                <div class="accordion-body">
                                  <div class="row">
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">Name
                                        <span class="text-danger">*</span>
                                      </label>
                                      <input type="text" formControlName="NAME_OF_EMERGENCY"
                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                        class="form-control form-control-sm" id="textfiled1">
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">Relationship
                                        <span class="text-danger">*</span>
                                      </label>
                                      <input type="text" formControlName="RELATIONSHIPNAME_OF_EMERGENCY"
                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                        class="form-control form-control-sm" id="textfiled1">
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <label class="form-label mb-1 float-start">Mobile Number<span
                                          class="text-danger">*</span>
                                      </label>
                                      <input type="text" (keypress)="onKeyPress($event)"
                                        formControlName="MOBILE_NO_EMERGENCY" maxlength="12"
                                        class="form-control form-control-sm">
                                    </div>
                                    <!-- -------------------------------------- -->
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <label class="form-label mb-1 float-start">Address
                                      </label>
                                      <input type="text" formControlName="ADDRESS_OF_EMERGENCY"
                                        class="form-control form-control-sm">
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <label class="form-label mb-1 float-start">Postal Code
                                      </label>
                                      <input type="text" formControlName="POSTALCODE_OF_EMERGENCY"
                                        class="form-control form-control-sm">
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <label class="form-label mb-1 float-start">Town/City
                                      </label>
                                      <input type="text" formControlName="TOWNCITY_OF_EMERGENCY"
                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                        class="form-control form-control-sm">
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <label class="form-label mb-1 float-start">Country
                                      </label>
                                      <select class="form-control form-control-sm"
                                        formControlName="COUNTRYID_OF_EMERGENCY">
                                        <option value="">Select</option>
                                        <option *ngFor='let item of country' [value]='item.DICTIONARYID'>
                                          {{item.DICTIONARYNAME}}</option>
                                      </select>
                                    </div>

                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <label class="form-label mb-1 float-start">Email Address
                                      </label>
                                      <input type="text" formControlName="EMAILID_OF_EMERGENCY"
                                        (blur)="validateEmail($event, 'Email')" [(ngModel)]="Emailid"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                        class="form-control form-control-sm">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                  data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                                  aria-controls="panelsStayOpen-collapseThree">
                                  (C) Previous Education Details
                                </button>
                              </h2>
                              <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
                                aria-labelledby="panelsStayOpen-headingThree">
                                <div class="row float-end pt-2">

                                  <div class="mb-3">
                                    <button class="btn btn-info btn-sm" (click)="add()">Add</button>
                                    <!-- &nbsp;&nbsp;
                                          <button class="btn btn-info btn-sm" (click)="remove()">Remove</button> -->
                                  </div>
                                </div>
                                <div class="accordion-body">


                                  <div class="row pt-4" *ngFor="let row of educationData;let i = index">

                                    <div class="col-xl-3 col-md-6 col-12 mb-3">
                                      <label for="textfiled1" class="form-label mb-1 float-start"> {{i+1}}.
                                        School/College/University <span class="text-danger">*</span>
                                      </label>
                                      <input type="text" [(ngModel)]="row.INSTITUTION_NAME"
                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                        [ngModelOptions]="{ standalone: true }" class="form-control form-control-sm"
                                        id="textfiled1">
                                    </div>
                                    <div class="col-xl-3 col-md-6 col-12 mb-3">
                                      <label for="textfiled1" class="form-label mb-1 float-start">Qualification
                                        Obtained<span class="text-danger">*</span>
                                      </label>
                                      <input type="text" [(ngModel)]="row.QUALIFICATION"
                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                        [ngModelOptions]="{ standalone: true }" class="form-control form-control-sm"
                                        id="textfiled1">
                                    </div>
                                    <div class="col-xl-3 col-md-6 col-12 mb-3">
                                      <label for="fromDate" class="form-label mb-1 float-start">From<span
                                          class="text-danger">*</span></label>
                                      <input type="date" [(ngModel)]="row.FROM_PERIOD"
                                        [ngModelOptions]="{ standalone: true }" class="form-control form-control-sm"
                                        id="fromDate" (change)="validateDates(row)">
                                    </div>
                                    <div class="col-xl-3 col-md-6 col-12 mb-3">
                                      <label for="toDate" class="form-label mb-1 float-start">To<span
                                          class="text-danger">*</span></label>
                                      <input type="date" [(ngModel)]="row.TO_PERIOD"
                                        [ngModelOptions]="{ standalone: true }" class="form-control form-control-sm"
                                        id="toDate" (change)="validateDates(row)">
                                      <div *ngIf="isToDateInvalid(row)" class="text-danger">
                                        To date should not be greater than From date.
                                      </div>
                                    </div>

                                    <!-- <div class="col-xl-3 col-md-6 col-12 mb-3">
                                      <label class="form-label mb-1 float-start">Address<span
                                          class="text-danger">*</span></label>
                                      <input type="text" [(ngModel)]="row.ADDRESS"
                                        [ngModelOptions]="{ standalone: true }" class="form-control form-control-sm">

                                    </div> -->

                                    <div class="col-xl-3 col-md-6 col-12 mb-3">
                                      <label class="form-label mb-1 float-start">Additional Information<span
                                          class="text-danger">*</span></label>
                                      <input type="text" [(ngModel)]="row.ADDITIONAL_INFORMATION"
                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                        [ngModelOptions]="{ standalone: true }" class="form-control form-control-sm">

                                    </div>
                                    <div class="col-xl-3 col-md-6 col-12 mb-3">
                                      <label for="toDate" class="form-label mb-1 float-start">Exam No.(KCSE Index No.)
                                        <span class="text-danger">*</span></label>
                                      <input type="text" [(ngModel)]="row.EXAM_REG_NO"
                                        [ngModelOptions]="{ standalone: true }" class="form-control form-control-sm">

                                    </div>
                                    <div class="col-xl-3 col-md-6 col-12 mb-3 pt-4">
                                      <button class="btn btn-danger btn-sm " (click)="remove(i)">Remove</button>
                                    </div>

                                  </div>


                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                  data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false"
                                  aria-controls="panelsStayOpen-collapseFour">
                                  (D) Upload Education Supporting Documents

                                </button>
                              </h2>
                              <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse"
                                aria-labelledby="panelsStayOpen-headingFour">
                                <div class="accordion-body">
                                  <div class="row">
                                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">Education Suppporting
                                        Documents<span class="text-danger">*</span>
                                      </label>
                                      <input type="file" formControlName="UPLOAD_1" (change)="changeFile($event,1)"
                                        class="form-control form-control-sm" id="textfiled1">
                                      <p class="text-danger float-start">Max 30Mb (upload only PDF,JPG,PNG,JPEG)</p><br>
                                      <p *ngIf="upload_1 != ''">{{upload_1}}</p>
                                      <!-- <div *ngIf="uploading">
                                        <progress value="{{ progress }}" max="100"></progress>
                                        <p>{{ progress }}%</p>
                                      </div> -->
                                    </div>

                                    <!-- <div class="col-xl-4 col-md-6 col-12 mb-3">
                                    <label for="textfiled1" class="form-label mb-1 float-start">High School Transcript<span
                                        class="text-danger">*</span>
                                    </label>
                                    <input type="file" formControlName="UPLOAD_2" (change)="changeFile($event,2)"
                                      class="form-control form-control-sm" id="textfiled1"  >
                                  </div> -->
                                    <!-- <div class="col-xl-4 col-md-6 col-12 mb-3">
                                    <label for="textfiled1" class="form-label mb-1 float-start">National ID/Passport<span
                                        class="text-danger">*</span>
                                    </label>
                                    <input type="file" formControlName="UPLOAD_3" (change)="changeFile($event,3)"
                                      class="form-control form-control-sm" id="textfiled1"  >
                                  </div> -->

                                    <div class="row">
                                      <div class="col-xl-6 col-md-6 col-12 mb-3">
                                        <label for="textfiled1" class="form-label mb-1 float-start">Portfolio
                                          Upload
                                        </label>
                                        <input type="file" formControlName="UPLOAD_3" (change)="changeFile($event,3)"
                                          class="form-control form-control-sm" id="textfiled1">
                                        <p class="text-danger float-start">Max 30Mb (upload only PDF,JPG,PNG,JPEG)</p>
                                        <br>
                                        <p *ngIf="upload_3 != 0">{{upload_3}}</p>
                                      </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-md-6 col-12 mb-3">
                                    <label for="textfiled1" class="form-label mb-1 float-start">Application Essay <span
                                        class="text-danger">*</span></label>
                                    <input type="file" formControlName="UPLOAD_5" (change)="changeFile($event,5)"
                                      class="form-control form-control-sm" id="textfiled1"  >
                                  </div> -->
                                    <!-- <div class="row">
                                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                                      <label for="textfiled1" class="form-label mb-3 float-start">Do you have any form of
                                        physical
                                        disability ? </label>
                                      <select formControlName="PHYSICAL_DISABILITY" class="form-control form-control-sm pt">
                                        <option value="">Select</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No
                                        </option>
        
                                      </select>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                                      <label for="textfiled1" class="form-label mb-1 float-start">How did you learn about OUK
                                        programmes? Please tick as appropriate </label>
        
                                      <select formControlName="OUK_PROGRAMMES" [(ngModel)]="oukPrograms"
                                        class="form-control form-control-sm">
                                        <option value="">Select</option>
                                        <option value="1">News papers</option>
                                        <option value="2">Television/Radio
                                        </option>
                                        <option value="3">OUK Website</option>
                                        <option value="4">Social media</option>
                                        <option value="5">Career Fair/ Exhibition</option>
                                        <option value="6">Others </option>
                                      </select>
                   
        
                                      <input type="text" class="form-control form-control-sm" *ngIf="oukPrograms == '6'">
                                    </div>
                                  </div> -->

                                  </div>



                                </div>
                              </div>
                            </div>
                            <div class="accordion-item">
                              <h2 class="accordion-header" id="panelsStayOpen-headingFive">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                  data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false"
                                  aria-controls="panelsStayOpen-collapseFive">
                                  (E) Working Experience
                                </button>
                              </h2>
                              <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse"
                                aria-labelledby="panelsStayOpen-headingFive">
                                <div class="row float-end  pt-2">
                                  <div class="mb-3">
                                    <button class="btn btn-info btn-sm" (click)="addApplicantDetails()">Add</button>

                                  </div>
                                </div>

                                <div class="accordion-body">

                                  <div class="row pt-4" *ngFor="let row of ApplicantData;let i = index">
                                    <div class="col-xl-3 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">{{i+1}}. Employer
                                      </label>
                                      <input type="text" [(ngModel)]="row.WORKEXP_DETAIL_EMPLOYER"
                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                        [ngModelOptions]="{ standalone: true }" class="form-control form-control-sm"
                                        id="textfiled1">
                                    </div>
                                    <div class="col-xl-3 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">Designation
                                      </label>
                                      <input type="text" [(ngModel)]="row.WORKEXP_DETAIL_DESIGNATION"
                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                        [ngModelOptions]="{ standalone: true }" class="form-control form-control-sm"
                                        id="textfiled1">
                                    </div>

                                    <div class="col-xl-3 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">From Year
                                      </label>
                                      <input type="date" [(ngModel)]="row.WORKEXP_DETAIL_FROM_YEAR"
                                        [ngModelOptions]="{ standalone: true }" class="form-control form-control-sm"
                                        id="textfiled1">
                                    </div>
                                    <div class="col-xl-3 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">To Year
                                      </label>
                                      <input type="date" [(ngModel)]="row.WORKEXP_DETAIL_TO_YEAR"
                                        [ngModelOptions]="{ standalone: true }" class="form-control form-control-sm"
                                        id="textfiled1">
                                    </div>
                                    <div class="col-xl-12 col-md-6 col-12 mb-3 pt-4 text-center">
                                      <button class="btn btn-danger btn-sm"
                                        (click)="removeApplicantDetails(i)">Remove</button>
                                    </div>
                                  </div>




                                </div>
                              </div>
                            </div>



                            <div class="accordion-item">
                              <h2 class="accordion-header" id="panelsStayOpen-headingSix">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                  data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false"
                                  aria-controls="panelsStayOpen-collapseSix">
                                  (F) Referees
                                </button>
                              </h2>
                              <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse"
                                aria-labelledby="panelsStayOpen-headingSix">
                                <div class="accordion-body">



                                  <div class="row">
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">Name
                                      </label>
                                      <input type="text" formControlName="SPONSORS_NAME"
                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                        class="form-control form-control-sm" id="textfiled1">
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">Mobile No
                                      </label>
                                      <input type="text" formControlName="SPONSORS_MOBILE" maxlength="12"
                                        class="form-control form-control-sm" id="textfiled1"
                                        (keypress)="onKeyPress($event)">
                                    </div>

                                    <div class="col-xl-4 col-md-6 col-12 mb-3">
                                      <!-- <span class="text-danger">*</span> -->
                                      <label for="textfiled1" class="form-label mb-1 float-start">Email
                                      </label>
                                      <input type="text" formControlName="SPONSORS_EMAIL"
                                        (blur)="validateEmail($event, 'sponserEmail')" [(ngModel)]="sponserEmail"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                        class="form-control form-control-sm" id="textfiled1">
                                    </div>
                                  </div>




                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- <div class="row">
                            <div class="col-xl-4 col-md-6 col-12 mb-3">
                              <label for="textfiled1" class="form-label mb-1 float-start">School<span
                                  class="text-danger">*</span>
                              </label>
                              <input type="text" formControlName="SECONDARY_SCHOOL_NAME2"
                                class="form-control form-control-sm" id="textfiled1"  >
                            </div>
                            <div class="col-xl-4 col-md-6 col-12 mb-3">
                              <label for="textfiled1" class="form-label mb-1 float-start">From<span
                                  class="text-danger">*</span>
                              </label>
                              <input type="date" formControlName="SECONDARY_FROM2" class="form-control form-control-sm"
                                id="textfiled1"  >
                            </div>
                            <div class="col-xl-4 col-md-6 col-12 mb-3">
                              <label for="textfiled1" class="form-label mb-1 float-start">To<span
                                  class="text-danger">*</span>
                              </label>
                              <input type="date" formControlName="SECONDARY_TO2" class="form-control form-control-sm"
                                id="textfiled1"  >
                            </div>
                          </div>
                          <br> -->


                        </div>
                      </form>



                    </div>
                  </div>
                  <!-- ------------------------------------------------------------------------------------------------------------------------------------------- -->
                  <!-- ----------------------------------------------------COURSE FEE DETAILS--------------------------------------------------------------------------- -->
                  <div class="text-center" *ngIf="aprvId == 3 && payId == 2">
                    <table>
                      <tr>
                        <td>
                          <span class="form-label line_2" style="font-size: 20px;"> Course
                            Name :</span>
                        </td>
                        <td>
                          <strong class="res">{{courseDetails.COURSE_NAME}}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span class="form-label line_2 " style="font-size: 20px;"> Tutor
                            Name :</span>

                        </td>
                        <td>
                          <strong class="res">{{courseDetails.FIRSTNAME}}
                            {{courseDetails.LASTNAME}}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span class="form-label line_2" style="font-size: 20px;">Total
                            Course
                            Fees :</span>
                          <!-- <strong>{{sessionDetails.COURSESHD_AMOUNT+GSTPercentages(sessionDetails.COURSESHD_AMOUNT)
    | currency:' KSH '}}</strong> -->
                          <!-- <strong>{{sessionDetails.COURSE_FEES
        | currency:' KSH '}}</strong></label> -->

                        </td>
                        <td>
                          <strong class="res">{{courseFee
                            | currency:' KSH '}}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span class="form-label line_2 " style="font-size: 20px;"> Mobile
                            Number :</span>

                        </td>
                        <td>
                          <input type="text" [(ngModel)]="mobileNumber" [ngModelOptions]="{standalone: true}">
                        </td>
                      </tr>
                    </table>
                  </div>

                  <!-- ---------------------------------------------------- REGISTRATION STATUS ------------------------------------------------------------------------------------------------- -->
                  <div class="text-center" *ngIf="aprvId == 0 && payId == 2">
                    <p> Your Approval is Pending!</p>
                  </div>
                  <!-- ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
                </div>
              </div>
            </div>


          </fieldset>

          <!-- ---------------------------------------------------------------------------FOOTER---------------------------------------------------------------------------------------- -->
          <div class="modal-footer">
            <!-- *ngIf="aprvId == 1" -->
            <button type="button" *ngIf="aprvId == 3 && payId==2" (click)="onPaymentClicked()"
              class="btn btn-sm btn-save ">Pay
              Now</button>
            <!-- *ngIf="aprvId != 1 || aprvId==2" -->
            <!-- *ngIf="(aprvId == 0|| aprvId==2 ) && (payId == 0)" -->
            <!-- data-bs-target="#applicationForm" data-bs-toggle="modal" -->
            <button class="btn btn-save btn-sm" *ngIf="(aprvId == 0|| aprvId==2 ) && (payId == 0)"
              (click)="save(signUpForm,'0');openModal2()">Preview</button>
            <button type="button" (click)="save(signUpForm,'1')" *ngIf="(aprvId == 0|| aprvId==2 ) && (payId == 0)"
              [disabled]="signUpForm.invalid" class="btn btn-sm btn-save">Submit</button>
            <!-- [disabled]="signUpForm.invalid" -->
            <!-- [disabled]="signUpForm.invalid" -->
            <button type="button" class="btn btn-sm btn-danger " id='md_close' (click)="close()"
              data-bs-dismiss="modal">Close</button>
          </div>
          <!-- -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        </form>
      </div>
    </div>
  </div>
</div>
<!-- ---------------------------------------------------------------------------------------USER-APPLICATION FORM-POPUP------------------------------------------------------------------------ -->
<div class="modal" data-backdrop="static" data-keyboard="false" id="applicationForm">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Application Details</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="Closemodal"></button>
      </div>
      <div class="modal-body">
        <div id="printtable">


          <table width="980" cellspacing="0" cellpadding="0" style="margin: auto;">
            <tbody>
              <tr>
                <td>
                  <table width="980" border="0" cellspacing="10" cellpadding="0">
                    <tbody>
                      <tr>
                        <td height="" style="text-align: center; " valign="middle"><img
                            src="../../../../assets/img/ouk-logo-new.png" width="135" alt=""></td>
                        <td rowspan="4">
                          <div class="pt-2 d-flex" style="width: 150px; height: 150px;">
                            <img src="../assets/img/sample-photo.jpg" class="img-fluid m-auto"
                              style="margin: auto; max-height: 100%;" alt="">
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center; ">
                          <h3 style="margin-top: 0; margin-bottom: 0;"> OPEN UNIVERSITY OF KENYA</h3>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center; ">
                          <p style="margin: 0px;">P.O. BOX xxxxxxxx, NAIROBI KENYA. TELEPHONE: XXXXXX, EMAIL :
                            registration@ouk.ac.ke</p>

                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center; ">
                          <h4 style="margin-top: 0; margin-bottom: 0;"> Office of the Director, School of Open,
                            Distance and eLearning </h4>
                        </td>
                      </tr>


                    </tbody>
                  </table>
                </td>

              </tr>


              <tr>
                <td colspan="2" style="padding: 10px">
                  <table width="100%" border="0" cellspacing="0" cellpadding="6"
                    style="border-top:2px dashed #333; font-size:13px;">
                    <tbody>
                      <tr>
                        <td colspan="4" style="font-size:16px; padding:15px 0px 10px 0px;"><b><u> (A) PERSONAL
                              DETAILS</u></b>
                        </td>
                      </tr>
                      <tr>
                        <td width="24%"><b>First Name</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td width="24%">{{userDetails?.result?.firstname}}</td>
                        <td width="24%"><b>Middle Name</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td width="24%">{{userDetails?.result?.middlename}}</td>
                      </tr>
                      <tr>
                        <td width="24%"><b>Last Name</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td width="24%">{{userDetails?.result?.lastname}}</td>
                        <td width="24%"><b>Email ID</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td width="24%">{{userDetails?.result?.username}}</td>
                      </tr>

                      <!-- <tr>
                                          <td><b>KCSE Index No</b></td>
                                          <td width="2%" style="text-align: center;">:</td>
                                          <td> {{userDetails?.result?.kcse_index_no}}</td>
                                          <td><b>Postal Address</b></td>
                                          <td width="2%" style="text-align: center;">:</td>
                                          <td>{{userDetails?.result?.postal_address}}</td>
                                      </tr> -->

                      <tr>
                        <td><b>P.O.Box</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.postal_address}} </td>
                        <td><b>Postal Code</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.postalcode_of_emergency}}</td>
                      </tr>
                      <tr>
                        <td><b>Physical Address</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.physical_address}}</td>
                        <td><b>Birth Date</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.dob}}</td>
                      </tr>

                      <tr>
                        <td><b>Nationality</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.nationality}}</td>
                        <td><b>Town/City</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.nearest_town}}</td>
                      </tr>
                      <tr>
                        <td><b>Country</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.country_name}}</td>
                        <td><b>ID/Passport No</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.id_passport}}</td>
                      </tr>
                      <tr>
                        <td><b>Gender</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.gender_name}}</td>
                        <td><b>Mobile Number</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.mobileno}}</td>
                      </tr>

                      <tr>
                        <td><b>Martial Status</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.maritalstatus_name}}</td>
                        <td><b>Languages</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.specify_highest_qualification}}</td>
                        <!-- <td><b>Specify Heighest Qualification</b></td>
                                          <td width="2%" style="text-align: center;">:</td>
                                          <td>{{userDetails?.result?.specify_highest_qualification}}</td> -->
                      </tr>
                      <tr>
                        <td><b>Program Selected</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.course_name}}</td>
                        <td><b>Mode Of Study</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.mode_of_study}}</td>
                      </tr>
                      <tr>
                        <td colspan="6" style="border-bottom:1px dashed #333; font-size:13px;">
                          <h5><b>(B) Next of Kin/Emergency Contact :</b></h5>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Name</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.name_of_emergency}}</td>
                        <td><b>Relationship</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.relationshipname_of_emergency}}</td>
                      </tr>
                      <tr>
                        <td><b>Mobile Number</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.mobile_no_emergency}}</td>
                        <td><b>Address</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.address_of_emergency}}</td>
                      </tr>
                      <tr>
                        <td><b>Postal Code</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.postalcode_of_emergency}}</td>
                        <td><b>Town/City</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.towncity_of_emergency}}</td>

                      </tr>

                      <tr>
                        <td><b>Country</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.country_name}}</td>
                        <td><b>Email ID</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td>{{userDetails?.result?.emailid_of_emergency}}</td>

                      </tr>
                      <tr style="border-bottom:0px dashed #333; font-size:13px;">
                        <td colspan="6">
                          <h5><b>(C) Previous Education Details :</b></h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div *ngFor="let item of userDetails?.lst">
                    <table width="100%" border="1" cellspacing="0" cellpadding="6">
                      <tbody>
                        <tr>
                          <td width="24%"><b>School/College/University </b></td>
                          <td width="2%" style="text-align: center;">:</td>
                          <td width="24%" colspan="3">{{item.INSTITUTION_NAME}}</td>
                          <td width="24%"><b>Qualification Obtained</b></td>
                          <td width="2%" style="text-align: center;">:</td>
                          <td width="24%">{{item.QUALIFICATION}}</td>
                        </tr>
                        <tr>
                          <td width="24%"><b>From</b></td>
                          <td width="2%" style="text-align: center;">:</td>
                          <td width="24%">{{item.FROM_PERIOD | date:'yyyy-MM-dd'}}</td>
                          <td width="24%"><b>To</b></td>
                          <td width="2%" style="text-align: center;">:</td>
                          <td width="24%">{{item.TO_PERIOD | date:'yyyy-MM-dd'}}</td>

                        </tr>
                        <!-- <tr>
                                              <td><b>Address</b></td>
                                              <td width="2%" style="text-align: center;">:</td>
                                              <td > {{item.ADDRESS}}</td>
                                              <td><b>Additional Information</b></td>
                                              <td width="2%" style="text-align: center;">:</td>
                                              <td>{{item.ADDITIONAL_INFORMATION}}</td>
                                          </tr> -->
                        <tr>
                          <td><b>Exam No./Exam Reg No</b></td>
                          <td width="2%" style="text-align: center;">:</td>
                          <td colspan="4"><span>{{item.EXAM_REG_NO}}</span></td>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <table width="100%" border="0" cellspacing="0" cellpadding="6">
                    <tbody>
                      <tr style="border-bottom:0px dashed #333; font-size:13px;">
                        <td colspan="6">
                          <h5><b>(D) Working Experience</b></h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div *ngFor="let item of userDetails?.lstt">
                    <table width="100%" border="1" cellspacing="0" cellpadding="6">
                      <tbody>

                        <tr>
                          <td width="24%"><b>Employer</b></td>
                          <td width="2%" style="text-align: center;">:</td>
                          <td width="24%">{{item.WORKEXP_DETAIL_EMPLOYER}}</td>
                          <td width="24%"><b>Designation</b></td>
                          <td width="2%" style="text-align: center;">:</td>
                          <td width="24%">{{item.WORKEXP_DETAIL_DESIGNATION}}</td>

                        </tr>
                        <tr>
                          <td width="24%"><b>From Year</b></td>
                          <td width="2%" style="text-align: center;">:</td>
                          <td width="24%">{{item.WORKEXP_DETAIL_FROM_YEAR | date:'yyyy-MM-dd'}}</td>
                          <td width="24%"><b>To Year</b></td>
                          <td width="2%" style="text-align: center;">:</td>
                          <td width="24%">{{item.WORKEXP_DETAIL_TO_YEAR | date:'yyyy-MM-dd'}}</td>

                        </tr>

                      </tbody>
                    </table>
                  </div>
                  <table width="100%" border="0" cellspacing="0" cellpadding="6">
                    <tbody>

                      <tr style="border-bottom:1px dashed #333; font-size:13px;">
                        <td colspan="6">
                          <h5><b>(E) Referees :</b></h5>
                        </td>
                      </tr>

                      <tr>
                        <td width="24%"><b>Name</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td width="24%">{{userDetails?.result?.sponsors_name}}</td>
                        <td width="24%"><b>Mobile No</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td width="24%">{{userDetails?.result?.sponsors_mobile}}</td>

                      </tr>
                      <tr>
                        <td width="24%"><b>Email Id</b></td>
                        <td width="2%" style="text-align: center;">:</td>
                        <td width="74%" colspan="3"> {{userDetails?.result?.sponsors_email}}</td>
                      </tr>

                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="footer float-end pt-2">
          <button (click)="closeModal()" id="Closemodal" class="btn btn-sm btn-danger"
            data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
          <button class="btn btn-save btn-sm" (click)="downloadForm()"> Download</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- -->